import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from "./api";

function ExportButton() {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
  setLoading(true);
    api.get('api/export-products')
    .then(response => {
      for(let server in response.data.status){
        if(response.data.status[server]==='success') toast.success(`Exported successfully to ${server}`, { autoClose: 5000 });
        else toast.error(`Export to ${server} failed`, { autoClose: 5000 });
      }
      
    })
    .catch(error => {
      toast.error('An error occurred: ' + error.message, { autoClose: 5000 });
    }).finally(() => {
      setLoading(false); // Reset loading to false once request is complete
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        onClick={handleClick}
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
        sx={{
          color: 'white', // Set text color to white
          borderColor: 'white', // Set border color to white
          '&:hover': {
            borderColor: 'white', // Maintain white border color on hover
          }
        }}
      >
        {loading ? 'Exporting...' : 'Export Products'}
      </Button>
    </div>
  );
}

export default ExportButton;
