import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Use Routes and Navigate in v6
import Login from './components/Login/Login';
import ProtectedRoute from './components/ProtectedRoute'; // ProtectedRoute component
import Dashboard from './components/Dashboard';
import Header from "./components/header";
import ProductLayout from "./components/ProductLayout";
import Settings from "./components/settings";
import PublicRoute from "./components/PublicRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const [owners, setOwners] = useState(() => {
    // Get owners from localStorage if available
    const savedOwners = localStorage.getItem('owners');
    return savedOwners ? JSON.parse(savedOwners) : [];
  });
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  return (
    <>
    <Router>
      {isAuthenticated && <Header owners={owners} setIsAuthenticated={setIsAuthenticated} />} 
      <Routes>
        {/* Public route for login */}
        <Route 
          path="/login" 
          element={<PublicRoute component={() => <Login setIsAuthenticated={setIsAuthenticated} setUser={setUser} setOwners={setOwners} />} isAuthenticated={isAuthenticated} />} 
        />

        {/* Protected routes */}
        <Route path="/dashboard" element={<ProtectedRoute component={() => <Dashboard currentUser={user.name} />} />} />
        <Route path="/settings" element={<ProtectedRoute component={() => <Settings currentUser={user.name} />} />} />
        {owners.map(owner => (
          <Route 
            key={owner} 
            path={`/products/${owner}`} 
            element={
              <ProtectedRoute component={() => (
                <ProductLayout owner={owner} />
              )} />
            }
          />
        ))}

        {/* Default to protected route (can redirect to a dashboard or any protected page) */}
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
     <ToastContainer position="top-right" style={{ top: "60px" }} autoClose={5000} />
     </>
  );
}

export default App;