import React from 'react';
import { TextField, Box } from '@mui/material';

const SearchBar = ({ handleInputChange }) => {
  return (
    <Box sx={{ width: 300, marginBottom: 2, marginTop: 2 }}>
      <TextField
        fullWidth
        label="Search by part number..."
        variant="outlined"
        onChange={handleInputChange}
      />
    </Box>
  );
};

export default SearchBar;