import React, { useState } from 'react';
import { Card, CardContent, CardActions, Typography, Button, Box } from '@mui/material';
import api from './api';

const FileUpload=({ owner }) =>{
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const submitFile = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);

        api.post(`api/upload/${owner}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then(response => {
            console.log(response);
            setUploadStatus(response.data.message);
        }).catch(error => {
            console.log(error);
            setUploadStatus("File upload failed.");
        });
        setFile(null);
    setUploadStatus('File is being processed!');
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const fileType = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
    
        if (fileType !== 'csv' && fileType !== 'xls' && fileType !== 'xlsx') {
            setUploadStatus("Invalid file format. Please upload a .csv or .xls(x) file.");
            return;
        }
    
        setFile(file);
        setUploadStatus("");
    };
    

    return (
        <Card sx={{ width: 300, padding: 2 }}> {/* Use sx for styling */}
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Upload File
            </Typography>
            <form onSubmit={submitFile}>
              <Box sx={{ marginBottom: 2 }}>
                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ width: '100%' }} // Make the input field full-width
                />
              </Box>
              <CardActions>
                <Button type="submit" variant="contained" color="primary">
                  Upload
                </Button>
              </CardActions>
            </form>
    
            {uploadStatus && (
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                {uploadStatus}
              </Typography>
            )}
          </CardContent>
        </Card>
      );
    
}

export default FileUpload;
