import React, { useEffect, useState } from "react";
import api from "./api";

import { TextField, Typography, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box } from '@mui/material';


const Dashboard = ({ currentUser }) => {
 
  const [partNumber, setPartNumber] = useState('');
  const [results, setResults] = useState([]);
  
  function debounce(fn, delay) {
    let timer;
    return function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }
  const handleSearch = async (e) => {
    //console.log(e.target.value);
    try {
      const response = await api.get(`api/search?partNumber=${e.target.value}`);
      setResults(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const debouncedHandleSearch =(e)=> {debounce(handleSearch(e), 300);}; // Adjust the delay as per your preference. 300ms is generally a good value.

const handleInputChange = (e) => {
  setPartNumber(e.target.value);
  debouncedHandleSearch(e);
};
  return (
    <Box sx={{ padding: 3 }}>
    {/* Welcome Message */}
    <Typography variant="h4" gutterBottom>
      Welcome, {currentUser}!
    </Typography>
    <Typography variant="body1" gutterBottom>
      This is your dashboard.
    </Typography>

    {/* Search Section */}
    <Box sx={{ marginTop: 3 }}>
      <TextField
        label="Search by part number"
        variant="outlined"
        onChange={handleInputChange}
        sx={{ width: '300px' }}
      />
    </Box>

    {/* Results Table */}
    {partNumber && results.length > 0 && (
      <Paper sx={{ marginTop: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Part Number</strong></TableCell>
              <TableCell><strong>Qty</strong></TableCell>
              <TableCell><strong>Owner</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.PartNo}</TableCell>
                <TableCell>{product.Qty}</TableCell>
                <TableCell>{product.owner}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    )}
  </Box>
);
};

export default Dashboard;
