import React, { useState, useEffect } from "react";
import api from './api';
import { Card, CardContent, CardActions, Typography, Button } from '@mui/material';
import { useLocation } from "react-router-dom";
function ExcelDownload() {
  const location = useLocation();
  const currentPageName = location.pathname;
  const pageIdentifier = currentPageName.split("/").pop();
  const [lastModifiedDate, setLastModifiedDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
      const fetchLastModified = async () => {
          try {
              const timestamp = new Date().getTime();
              const response = await api.head(`api/exports/${pageIdentifier}?${timestamp}`);
              
              const dateHeader = response.headers['last-modified'];
              if (dateHeader) {
                  setLastModifiedDate(new Date(dateHeader));
                  setErrorMessage(null);
              }
          } catch (error) {
              console.error("Error fetching last modified date:", error.message);
              setErrorMessage("File not found or could not retrieve file information.");
          }
      };

      fetchLastModified();
  }, []);

  const handleDownload = async () => {
    try {
        const response = await api.get(`api/exports/${pageIdentifier}`, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        
        // Set the correct file name based on the page
        const downloadFileName = pageIdentifier === 'Automa' ? 'automa_products.xlsx' : 'SYNCREL IN-STOCK INVENTORY.xlsx';
        link.setAttribute('download', downloadFileName);
        
        document.body.appendChild(link);
        link.click();
        link.remove();
        setErrorMessage(null);
    } catch (error) {
        console.error("Error downloading file:", error.message);
        setErrorMessage("File not found or could not be downloaded.");
    }
};
    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1; // January is 0
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

    };
    return (
        <Card sx={{ textAlign: 'left', width: 500, padding: 2 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Stock File
            </Typography>
    
            <CardActions>
              <Button variant="contained" color="primary" onClick={handleDownload}>
                Download Excel
              </Button>
            </CardActions>
    
            {lastModifiedDate && (
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                Last Upload: {formatDate(lastModifiedDate)}
              </Typography>
            )}
          </CardContent>
        </Card>
      );
}
  
  export default ExcelDownload;

  
  
  
  
  