import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ExportButton from "./exportProducts";
import { AppBar, Toolbar, Button, Typography } from "@mui/material";
import api from "./api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { Settings, Logout } from '@mui/icons-material';

const Header = ({ owners, setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentOwner = location.pathname.split("/")[2] || "Dashboard";

  const handleLogout = () => {
    api.post("api/auth/logout").then((response) => {
      if (response.status === 200) {
        // Remove token from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('owners');
        setIsAuthenticated(false);
        // Navigate to the login page after successful logout
        navigate('/login');
      }
    });
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
    handleMenuClose();
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            SyncStock
          </Link>
        </Typography>
        {owners.map((owner) => (
          <Button
            key={owner}
            component={Link}
            to={`/products/${owner}`}
            color={currentOwner === owner ? "secondary" : "inherit"} // Highlight current owner
            variant={currentOwner === owner ? "contained" : "text"}
            sx={{ marginRight: 1 }}
          >
            {owner}
          </Button>
        ))}
        <ExportButton />
        <div>
        <IconButton color="inherit" onClick={handleMenuOpen}>
        <AccountCircleIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleSettingsClick}>
          <Settings /> Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Logout /> Logout
        </MenuItem>
      </Menu>
      </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
