import api from './api';

const getCsrfCookie = async () => {
  try {
    await api.get('/sanctum/csrf-cookie');
  } catch (error) {
    console.error('Error fetching CSRF cookie:', error);
  }
};

export default getCsrfCookie;