import React,{useState} from "react";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Button,
  Paper,
} from '@mui/material';

const SearchTable = ({ results, selectedResults, setSelectedResults, setEditProduct, setModal2IsOpen, setEditedQuantity }) => {
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const handleCheckboxChange = (e, result) => {
    if (e.target.checked) {
      setSelectedResults([...selectedResults, result]);
    } else {
      setSelectedResults(selectedResults.filter((r) => r.id !== result.id));
    }
  };
  const handleEditClick = (product) => {
    setEditProduct(product);
    setEditedQuantity(product.Qty.toString());
    setModal2IsOpen(true); // Open the modal when the Edit button is clicked
  };
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Table sx={{ minWidth: 650 }} aria-label="products table">
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Owner</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((product, index) => (
            <TableRow
              key={product.PartNo}
              onMouseEnter={() => setHoveredProductId(product.id)}
              onMouseLeave={() => setHoveredProductId(null)}
              hover
            >
              <TableCell>
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e, product)}
                  inputProps={{ 'aria-label': `Select ${product.PartNo}` }}
                />
              </TableCell>
              <TableCell>{product.PartNo}</TableCell>
              <TableCell>
              <Box display="flex" justifyContent="flex-start" gap={2} alignItems="center">
                  
                  
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleEditClick(product)}
                  >
                    Edit
                  </Button>
                  <span>{product.Qty}</span>
              </Box>
              </TableCell>
              <TableCell>{product.owner}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SearchTable;
