import React from 'react';
import { Navigate } from 'react-router-dom';

// A protected route component that checks for authentication
const ProtectedRoute = ({ component: Component }) => {
  const isAuthenticated = !!localStorage.getItem('token'); // Check for token

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;