import React, { useState } from 'react';
import { useLocation } from "react-router-dom";
import { TextField, Button, Box, Typography } from '@mui/material';
function AddProductForm({ onSubmit, successMessage }) {
  const [PartNo, setPartNumber] = useState('');
  const [Mfc, setManufacturer] = useState('');
  const [Qty, setQuantity] = useState('');
  const [Desc, setDescription] = useState('');
  const location = useLocation();
  const currentPageName = location.pathname;
  const owner = currentPageName.split("/").pop();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(owner);
    onSubmit({ PartNo, Mfc, Qty, Desc,owner });
    setPartNumber('');
    setManufacturer('');
    setQuantity('');
    setDescription('');
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, mx: 'auto', mt: 4 }}
    >
      {/* Part Number */}
      <TextField
        label="Part Number"
        value={PartNo}
        onChange={(e) => setPartNumber(e.target.value)}
        variant="outlined"
        fullWidth
        required
      />
      
      {/* Manufacturer */}
      <TextField
        label="Manufacturer"
        value={Mfc}
        onChange={(e) => setManufacturer(e.target.value)}
        variant="outlined"
        fullWidth
        required
      />

      {/* Quantity */}
      <TextField
        label="Quantity"
        value={Qty}
        onChange={(e) => setQuantity(e.target.value)}
        type="number"
        variant="outlined"
        fullWidth
        required
      />

      {/* Description */}
      <TextField
        label="Description"
        value={Desc}
        onChange={(e) => setDescription(e.target.value)}
        variant="outlined"
        fullWidth
      />

      {/* Submit Button */}
      <Button variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
        Add Product
      </Button>

      {/* Success Message */}
      {successMessage && (
        <Typography color="success" variant="body1" sx={{ mt: 2 }}>
          {successMessage}
        </Typography>
      )}
    </Box>
  );
}

export default AddProductForm;