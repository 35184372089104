import React from 'react';
import { Box, Typography } from '@mui/material';
import ProductsPage from './list'; // Your ProductsPage component (from list.js)
import FileUpload from './fileUpload'; // Your FileUpload component (from fileUpload.js)
import ExcelDownload from './excelDownload'; // Your ExcelDownload component (from excelDownload.js)

const ProductLayout = ({ owner }) => {
  const specialOwners = ['Automa', 'NetComp']; // Special pages that use ExcelDownload

  return (
    <Box sx={{ padding: 3 }}> {/* Center the content with left and right padding */}
      <Typography variant="h4" gutterBottom>
        Products for {owner}
      </Typography>

      {/* Conditionally render FileUpload or ExcelDownload */}
      {specialOwners.includes(owner) ? (
        <ExcelDownload owner={owner} />
      ) : (
        <FileUpload owner={owner} />
      )}

      {/* Always render ProductsPage */}
      <ProductsPage owner={owner} />
    </Box>
  );
};

export default ProductLayout;