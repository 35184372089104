import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import api from './api';
// import EnableTwoFa from './enable-two-fa.component';

const Settings = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Form submission handler
  const handleRegister = (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setError('Passwords do not match!');
      return;
    }

    // Clear any previous error or success messages
    setError('');
    setSuccess('');

    // Prepare the form data
    const formData = {
      name,
      email,
      password,
      password_confirmation: passwordConfirmation
    };

    // Make the POST request to /auth/register
    api.post('api/auth/register', formData)
      .then((response) => {
        setSuccess('User registered successfully!');
        // Clear the form
        setName('');
        setEmail('');
        setPassword('');
        setPasswordConfirmation('');
      })
      .catch((error) => {
        setError('Failed to register user. Please try again.');
        console.error('Registration error:', error);
      });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Settings</Typography>

      <Box sx={{ mt: 4, width:500 }}>
        <Typography variant="h6" gutterBottom>Register New User</Typography>
        <form onSubmit={handleRegister}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Confirm Password"
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            fullWidth
            required
            sx={{ mb: 2 }}
          />

          {error && (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}

          {success && (
            <Typography variant="body2" color="success" sx={{ mb: 2 }}>
              {success}
            </Typography>
          )}

          <Button type="submit" variant="contained" color="primary">
            Register User
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default Settings;
